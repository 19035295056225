html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
/* body {
  overflow-y: auto !important;
  height: 100vh;
}
html {
  overflow: hidden;
  height: 100vh;
} */

a {
  color: inherit;
  text-decoration: none;
}

#WEB3_CONNECT_MODAL_ID {
  position: relative;
  z-index: 999;
}
.navbar.navbar-expand-lg.navbar-dark .container-fluid {
  padding-left: 60px !important;
  padding-right: 50px !important;
  padding-top: 10px;
}
.web3modal-modal-lightbox {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.web3modal-modal-container {
  background: url("/assets/images/background-dialog-wallet.png") no-repeat
    center transparent !important;
  background-size: 680px 400px !important;
  /* -webkit-clip-path: polygon( 12% 0px,87% 0px,100% 0px,108% 72%,91% 100%,0% 100%,0px 100%,0px 18% );
    clip-path: polygon( 12% 0px,87% 0px,100% 0px,108% 72%,91% 100%,0% 100%,0px 100%,0px 18% ); */
  max-height: 400px !important;
  max-width: 700px !important;
  padding: 50px !important;
  width: 80% !important;
  position: relative !important;
}

.web3modal-modal-hitbox:before {
  content: "";
  background: url("/assets/images/close-dialog.svg") no-repeat center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: 70px;
  top: 67px;
  z-index: 9;
  cursor: pointer;
}

.web3modal-modal-card {
  border-radius: 0 !important;
  position: relative !important;
  overflow: hidden !important;
  background: url("/assets/images/wallet-dialog-bg.png") no-repeat center;
  background-size: 100% 100% !important;
  background-color: transparent !important;
}

.web3modal-modal-card {
  display: flex !important;
}

.web3modal-provider-wrapper {
  background: none !important;
}

.web3modal-provider-name {
  color: #fff !important;
  font-size: 16px !important;
  font-family: "Lato" !important;
}

.web3modal-provider-description {
  display: none !important;
}

.web3modal-provider-wrapper {
  border: none !important;
  background-color: #141e1e !important;
}

.web3modal-provider-wrapper:first-child {
  margin: 150px 20px 80px 140px !important;
}

.web3modal-provider-wrapper:last-child {
  margin: 150px 140px 80px 20px !important;
}

.web3modal-provider-wrapper:first-child::before {
  content: "Choose Wallet";
  display: flex;
  align-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 30px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.web3modal-provider-container {
  background-color: transparent !important;
}
.risponsive-connect-wallet {
  display: none;
}
@media all and (max-width: 990px) {
  .connect-wallet {
    display: none;
  }
  .risponsive-connect-wallet {
    position: absolute;
    right: 90px;
    top: 28px;
    display: inline;
    margin-left: 40px;
    height: 40px;
  }
  .risponsive-connect-wallet .connect-wallet {
    top: 0;
    height: 40px;
    display: inline;
  }
}

@media all and (max-width: 650px) {
  .web3modal-modal-card {
    background: #141e1e !important;
    border: 1px solid #95271e !important;
    min-width: 0 !important;
    margin: 0 !important;
  }

  .web3modal-provider-wrapper:first-child,
  .web3modal-provider-wrapper:last-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .show {
    position: absolute;
    background: rgb(20, 30, 30);
    left: 0px;
    right: 0px;
    top: 100%;
    padding: 30px;
    height: calc(100vh - 106px);
  }
  .navbar-nav a {
    padding-left: 0 !important;
    text-align: center;
    height: 80px;
  }
}

* {
  box-sizing: border-box;
}

.connect-wallet {
  background: none;
  border-radius: 6px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 20px;
  color: #d5372a;
  border: 2px solid #d5372a;
  padding: 0 20px;
  position: relative;
  top: -5px;
  margin-left: 40px;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/S6uyw4BMUTPHjxAwXjeu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/S6uyw4BMUTPHjx4wXg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    align-items: flex-end !important;
    place-content: flex-end !important;
    text-decoration: none !important;
    font-size: 35px !important;
    line-height: 50px !important;
    font-weight: 40px !important;
    padding-top: 20px !important;
    /* padding-bottom: 20px !important; */
    /* color: white !important; */
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row !important;
    padding-right: 60px !important;
    /* padding: 15px 20px;  */
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #f7f7f7 !important;
    /* padding: 15px 20px !important; */
  }
}

@font-face {
  font-family: "a Asian Hiro";
  src: url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.eot");
  src: url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.woff2") format("woff2"),
    url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.woff") format("woff"),
    url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.ttf") format("truetype"),
    url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.svg#a Asian Hiro")
      format("svg");
}

* {
  font-family: "a Asian Hiro";
}

.Home_container__Ennsq {
  padding: 0 !important;
}

.gallery-item {
  width: 200px;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #d5372a;
}

.navbar-nav a,
.navbar-nav span {
  padding-left: 40px;
  text-decoration: none !important;
  color: #fff !important;
  padding-top: 0 !important;
  font-size: 30px;
}

.navbar-nav a.active {
  color: rgb(213, 55, 42) !important;
}

.navbar-dark .navbar-nav .nav-link {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.navbar-nav a small {
  display: block;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
  color: #ffffff;
  padding: 14px 16px;
}

@media (max-width: 300px) {
  .show {
    position: absolute;
    background: #141e1e;
    left: 0;
    right: 0;
    top: 100%;
    padding: 30px;
    height: calc(100vh - 123px);
  }

  .show a {
    text-align: center;
    font-size: 35px;
    padding: 15px 0;
  }
}

.banner-text {
  width: 400px;
}

@media (max-width: 767px) {
  .banner-text {
    width: 300px;
  }

  .banner-text text {
    font-size: 35px !important;
  }
}

.stick {
  position: relative;
  padding-left: 60px;
}

.stick-img {
  position: absolute !important;
  left: 20px !important;
  top: 10px !important;
  width: 16px !important;
  height: 100% !important;
  bottom: 40px !important;
  max-width: none !important;
  max-height: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
}

.stick-black {
  background: url("/assets/images/black-stick.png") no-repeat left 22px top 5px;
  background-size: 20px 100%;
  padding-right: 22px;
}

.stick-white {
  background: url("/assets/images/white-stick.png") no-repeat left 22px top 5px;
  background-size: 20px 100%;
  padding-right: 5px;
}

#wrapper {
  height: 500px;
  overflow: auto;
}

.owl-carousel .owl-stage-outer {
  width: 100%;
}

.owl-stage {
  transform: none !important;
}

.owl-carousel .owl-item {
  opacity: 0 !important;
  display: none !important;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.owl-carousel .owl-item.active {
  opacity: 1 !important;
  z-index: 1;
  display: inline !important;
}

/* .owl-carousel .owl-item img {
  width: 100% !important;
  height: 100% !important;
} */

body {
  font-family: "a Asian Hiro";
  overflow-x: hidden;
  background-image: url("/assets/images/main-bg.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  background-image: url("/assets/images/main-bg.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: 9;
}

.loader-gallery {
  background-image: url("/assets/images/soulz-red-bg.png");
}

.loader img {
  position: fixed !important;
  width: 120px !important;
  height: 120px !important;
  min-width: 0 !important;
  min-height: 0 !important;
}
.loader-img {
  width: 120px !important;
  height: 120px !important;
  border: 0.5px solid #495057 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  justify-content: center;
  position: relative;
}
.loader-img-success {
  width: 104px !important;
  height: 120px !important;
  border: 0.5px solid #495057 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  justify-content: center;
  position: relative;
}
.loader-img-success img {
  width: 60px !important;
  height: 60px !important;
  min-height: 0 !important;
}
.loader-img-success > span:nth-last-child(2) {
  position: absolute !important;
  top: 25px;
}
.loader-img img {
  width: 60px !important;
  height: 60px !important;
  min-height: 0 !important;
}
.hide-image > span:nth-child(2) {
  display: none !important;
}
.red-section {
  background-image: url("/assets/images/red-circle-1.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  margin-top: -250px;
  height: calc(100% + 250px);
  background-size: 550px;
}

.navbar {
  z-index: 5 !important;
}

iframe {
  pointer-events: none;
}

video {
  max-width: 100%;
}

.rotate-animation {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes headerBg {
  0%,
  100% {
    background-position: top left;
  }

  20% {
    background-position: right center;
  }

  40% {
    background-position: bottom left;
  }

  60% {
    background-position: bottom right;
  }

  80% {
    background-position: top right;
  }
}

.sub-ment {
  position: absolute;
  right: 44px;
  top: 70px;
  background: #141e1e;
  padding: 12px 0 8px;
  min-width: 150px;
}

.sub-ment a {
  cursor: pointer;
  padding: 8px 20px;
}

.nav-link {
  cursor: pointer;
}

.footer-social div {
  margin: 0 3px;
}
@media all and (max-width: 990px) {
  .navbar.navbar-expand-lg.navbar-dark .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 5px;
  }
}
@media all and (max-width: 768px) {
  .loader-img {
    width: 65px !important;
    height: 73px !important;
    min-width: 100%;
    justify-content: center;
  }
  .loader-img img {
    width: 40px !important;
    height: 40px !important;
    min-height: 0 !important;
  }
  .btn-wrapper img {
    width: 15px !important;
    height: 15px !important;
  }
  .web3modal-modal-container {
    background: url("/assets/images/background-dialog-wallet-mobile.png")
      no-repeat center transparent !important;
    background-size: 100% 100% !important;
  }
  .web3modal-provider-wrapper:first-child {
    margin: 60px 20px 20px 15% !important;
  }
  .web3modal-provider-wrapper:last-child {
    margin: 60px 15% 20px 20px !important;
  }
  .web3modal-provider-wrapper:first-child::before {
    top: 20px;
    font-size: 20px;
  }
  .web3modal-provider-icon {
    width: 26px !important;
    height: 26px !important;
  }
  .web3modal-provider-name {
    font-size: 9px !important;
  }
  .web3modal-modal-container {
    max-height: 200px !important;
  }
  .web3modal-modal-container {
    padding: 30px !important;
  }
  .web3modal-modal-hitbox:before {
    right: 41px;
    top: 37px;
    transform: scale(0.8);
  }
}
@media all and (max-width: 600px) {
  .navbar {
    padding-right: 1rem !important;
  }
}

.search-box {
  margin: auto;
  position: relative;
  background: #223030;
}
.search-box input::placeholder {
  color: #b6b6b6;
}
.search-box input,
.search-box .search-btn {
  background: none !important;
  color: #fff !important;
  border: none;
  box-shadow: none !important;
  padding-top: 4px;
  padding-bottom: 1px;
  font-family: "Lato";
}

.search-box .search-btn {
  position: absolute;
  right: 0;
  top: 3px;
}
.fill-tab-example.nav-tabs {
  position: static !important;
}
.f-20 {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 400;
}

.connect-wallet-dialog-btn {
  font-family: "Lato" !important;
  color: #d5372a !important;
  border: 2px solid #d5372a !important;
  border-radius: 6px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 20px;
  padding: 20px 15px;
}
.table-heading-dilog,
.table-list-dilog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin: 20px auto 0;
}
.table-heading-dilog h2 {
  font-size: 12px;
  line-height: 20px;
  border-bottom: 2px solid #273636;
  color: #fff;
}
.table-list-dilog {
  border-bottom: 1px solid #273636;
  margin-top: 10px;
}
.table-list-dilog div {
  color: #fff;
  font-family: "Lato";
  font-size: 12px;
}
.table-list-dilog div.Claimed {
  color: #49df6a;
}
.table-list-dilog div.Available {
  color: #d5372a;
}
.scroll-dialog {
  max-height: 180px;
  overflow: auto;
  max-width: 92%;
  margin: auto;
}

.scroll-dialog::-webkit-scrollbar {
  width: 2px;
  background: #273636;
}

.scroll-dialog::-webkit-scrollbar-track {
  background: #273636;
}

.scroll-dialog::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 18.8px;
}
@media all and (max-width: 767px) {
  .scroll-dialog {
    max-height: 60px;
  }
  .search-box input,
  .search-box .search-btn {
    padding-top: 0;
    padding-bottom: 0;
  }
  .btn-wrapper button {
    margin-top: 8px !important;
    margin-right: -30px !important;
    transform: scale(0.6);
  }
  .btn-wrapper button.cancel-btn {
    margin-left: 40px !important;
  }
  .reroll-cancel button.cancel-btn {
    margin-right: -75px !important;
  }
}
.no-nfts {
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-family: "Lato";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tab-content {
  width: 100%;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.large-modal-risponsive .image-wrapper {
  margin-right: 10px;
  margin-bottom: 10px;
}
.large-modal-risponsive .nav.nav-tabs {
  width: 553px !important;
  padding-left: 55px !important;
}

.large-modal-risponsive .update-image-style,
.large-modal-risponsive .update-image-style img {
  width: 200px !important;
  height: 215px !important;
  min-width: 100%;
}
.large-modal-risponsive .update-image-style img {
  max-width: none !important;
  max-height: none !important;
  width: 200px !important;
  height: 215px !important;
}
.outer-hover-effect {
  position: relative;
  cursor: pointer;
}
.hover-effect {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(20, 30, 30, 0.75);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.outer-hover-effect:hover .hover-effect {
  opacity: 1;
}
.overflow-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 130px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  margin-left: -30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.right-section-wrapper .desktop {
  display: block;
}
.large-modal-risponsive .open-sea .mobile {
  display: none;
}
@media all and (max-width: 768px) {
  .large-modal-risponsive .text-style {
    font-size: 8px;
    font-weight: 300;
  }
  .large-modal-risponsive .loader-image img {
    width: 65px !important;
    height: 73px !important;
    min-width: 100%;
  }
  .loader-img-success {
    width: 104px !important;
    height: 115px !important;
  }
  .loader-img-success > span:nth-last-child(2) {
    position: absolute !important;
    top: 25px;
  }
  .large-modal-risponsive .no-ascendant {
    margin-top: 0px !important;
  }
  .large-modal-risponsive .no-ascendant div {
    font-size: 12px !important;
    margin-top: 40px !important;
  }

  .large-modal-risponsive .dialog-body-modal {
    min-height: 0 !important;
  }
  .large-modal-risponsive .tab-pane.show {
    padding: 0px 20px;
    position: static;
    height: auto;
    margin: 0 1px;
    background: none;
  }
  .large-modal-risponsive .image-style,
  .large-modal-risponsive .tab-content .image-style img {
    width: 65px !important;
    height: 73px !important;
    min-width: 100% !important;
  }

  .large-modal-risponsive .image-style img {
    max-width: none !important;
    max-height: none !important;
    width: 65px !important;
    height: 73px !important;
  }

  .large-modal-risponsive .heading-title {
    font-size: 8px !important;
  }
  .large-modal-risponsive li button,
  li button.active {
    width: 80px !important;
  }
  .large-modal-risponsive li {
    width: 80px !important;
  }
  .large-modal-risponsive .reroll-btn-wrapper {
    margin-top: 8px !important;
  }
  .large-modal-risponsive .dialog-body-modal h2 {
    font-size: 19px !important;
  }
  .dialog-outer-success {
    background-size: 100% 100% !important;
    padding: 30px !important;
    width: 90% !important;
  }
  .dialog-outer-success .dialog-content-success .failure-modal {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .dialog-content-success .failure-wrapper {
    padding: 60px 0px 0px 0px !important;
  }
  .large-modal-risponsive .dialog-body-modal,
  .dialog-outer-success .dialog-body-success {
    background: url("/assets/images/risponsive-dialog-large.png") no-repeat
      center !important;
    background-size: 100% 100% !important;
  }
  .large-modal-risponsive .tab-content {
    margin: 2px 2px 25px;
  }
  .large-modal-risponsive .scroll-dialog-modal {
    max-width: 100% !important;
  }
  .large-modal-risponsive .scroll-dialog-ascendant {
    max-width: 100% !important;
    margin-left: 0px !important;
    height: 80px !important;
  }
  .large-modal-risponsive .no-margin {
    margin-top: 0px !important;
  }
  .large-modal-risponsive .scroll-dialog-modal ~ div {
    position: static !important;
    width: 100%;
    justify-content: right;
  }
  .large-modal-risponsive .scroll-position .nav-tabs {
    margin: 0;
    width: calc(100% - 22px) !important;
    padding-left: 0 !important;
    position: relative;
    left: 8px;
  }
  .large-modal-risponsive .scroll-position .nav-tabs li {
    display: inline-block;
  }
  .large-modal-risponsive .dialog-outer-modal {
    padding: 22px !important;
  }
  .large-modal-risponsive .dialog-outer-modal {
    min-height: 400px !important;
    height: 400px !important;
  }
  .large-modal-risponsive li {
    margin-left: 0 !important;
  }
  .large-modal-risponsive .dialog-body-modal {
    min-height: 350px !important;
    height: 350px !important;
  }
  .large-modal-risponsive .image-wrapper {
    margin-right: 6px;
    margin-bottom: 15px;
  }
  .large-modal-risponsive .close-dialog-modal img {
    width: 10px !important;
    height: 10px !important;
  }
  .large-modal-risponsive .dialog-body-modal h3 {
    font-size: 12px !important;
    text-align: left !important;
    margin-top: 10px;
  }
  .large-modal-risponsive .scroll-dialog-modal {
    justify-content: left !important;
    height: 135px !important;
  }
  .large-modal-risponsive .update-image-style,
  .large-modal-risponsive .update-image-style img {
    width: 83px !important;
    height: 93px !important;
    min-width: 83px;
  }
  .large-modal-risponsive .open-sea img {
    width: 11px !important;
    height: 11px !important;
  }
  .large-modal-risponsive .open-sea div {
    font-size: 10px !important;
  }
  .large-modal-risponsive .open-sea .mobile {
    display: block;
  }
  .large-modal-risponsive .update-image-style img {
    max-width: none !important;
    max-height: none !important;
    width: 83px !important;
    height: 93px !important;
  }
  .large-modal-risponsive .dialog-body-modal h2 {
    display: none;
  }
  .left-section {
    flex-direction: row !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }
  .left-section > div:last-child {
    width: 100%;
    margin-left: 15px;
    margin-top: -9px;
  }
  .left-section-ascendant {
    flex-direction: row !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }
  .left-section-ascendant > div:last-child {
    width: 100%;
    margin-left: 15px;
  }
  .detial-dialog {
    margin-left: 0 !important;
    margin-top: 10px !important;
    flex-direction: column !important;
  }
  .right-section {
    margin-left: -21px !important;
    width: calc(100% + 130px) !important;
    height: 92px;
    overflow: auto;
  }
  .right-section-ascendant {
    margin-left: -10px !important;
    width: calc(100% + 28px) !important;
    height: 92px;
    overflow: auto;
  }
  .right-section-ascendant .loader-image {
    margin-top: 6px !important;
  }
  .right-section > div {
    width: 75px;
    margin-bottom: 10px;
  }
  .right-section-wrapper .desktop {
    display: none;
  }
  .right-section > div > div {
    padding: 5px;
  }
  .overflow-text,
  .right-section > div > div div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 60px;
    font-size: 7px;
  }

  .large-modal-risponsive .btn-container-reroll {
    margin-right: 0 !important;
    margin-top: 8px !important;
  }
  .large-modal-risponsive .btn-container {
    margin-right: 0 !important;
    margin-top: -20px;
  }
  .large-modal-risponsive .btn-container button {
    font-weight: 400 !important;
  }
  .large-modal-risponsive .detial-dialog {
    padding: 0px 0px 10px;
  }
  .large-modal-risponsive .btn-container .btn-wrapper {
    max-width: 115px;
  }
  .large-modal-risponsive .image-style img {
    width: 66px !important;
    height: 40px !important;
  }
}
.large-modal-risponsive .image-wrapper {
  margin-right: 10px;
  margin-bottom: 10px;
}
.large-modal-risponsive .nav.nav-tabs {
  width: 553px !important;
  padding-left: 55px !important;
}

@media all and (max-width: 768px) {
  .large-modal-risponsive .dialog-body-modal {
    min-height: 0 !important;
  }
  .large-modal-risponsive .tab-pane.show {
    padding: 0px 20px;
    position: static;
    height: auto;
    margin: 0 1px;
    background: none;
  }
  .large-modal-risponsive .image-style {
    width: 65px !important;
    height: 73px !important;
    min-width: 100%;
  }
  .large-modal-risponsive .image-style,
  .large-modal-risponsive .image-style img {
    width: 65px !important;
    height: 73px !important;
    min-width: 100%;
  }
  .large-modal-risponsive .image-style img {
    max-width: none !important;
    max-height: none !important;
    width: 65px !important;
    height: 73px !important;
  }
  .large-modal-risponsive .heading-title {
    font-size: 8px !important;
  }
  .large-modal-risponsive li button,
  li button.active {
    width: 80px !important;
  }
  .large-modal-risponsive li {
    width: 80px !important;
  }
  .large-modal-risponsive .dialog-body-modal h2 {
    font-size: 19px !important;
  }
  .large-modal-risponsive .detial-dialog .error-text {
    display: block;
  }
  .large-modal-risponsive .dialog-body-modal {
    background: url("/assets/images/risponsive-dialog-large.png") no-repeat
      center !important;
    background-size: 100% 100% !important;
  }
  .large-modal-risponsive .tab-content {
    margin: 2px 2px 25px;
  }
  .large-modal-risponsive .scroll-dialog-modal ~ div {
    position: static !important;
    width: 100%;
    justify-content: right;
  }
  .large-modal-risponsive .scroll-position .nav-tabs {
    margin: 0;
    width: calc(100% - 22px) !important;
    padding-left: 0 !important;
    position: relative;
    left: 8px;
  }
  .large-modal-risponsive .scroll-position .nav-tabs li {
    display: inline-block;
  }
  .large-modal-risponsive .dialog-outer-modal {
    padding: 22px !important;
  }
  .large-modal-risponsive .dialog-outer-modal {
    min-height: 400px !important;
    height: 400px !important;
  }
  .large-modal-risponsive li {
    margin-left: 0 !important;
  }
  .large-modal-risponsive .dialog-body-modal {
    min-height: 350px !important;
    height: 350px !important;
  }
  .large-modal-risponsive .image-wrapper {
    margin-right: 6px;
    margin-bottom: 15px;
  }
  .large-modal-risponsive .close-dialog-modal img {
    width: 10px !important;
    height: 10px !important;
  }
  .large-modal-risponsive .close-dialog-modal {
    top: 5px !important;
    right: 2px !important;
    z-index: 9;
  }
  .large-modal-risponsive .dialog-body-modal h3 {
    font-size: 12px !important;
    text-align: left !important;
    margin-top: 10px;
  }
  .large-modal-risponsive .scroll-dialog-modal {
    justify-content: left !important;
    height: 180px !important;
    margin-left: 0 !important;
    align-items: start;
    margin-top: 15px !important;
  }
}
